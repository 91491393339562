<template>
    <div style="margin: -15px -15px" class="iottery">
      <div class="iottery-title">{{liveTitle}}</div>
      <a-form-model
          ref="ruleForm"
          :model="topForm"
          :rules="rules"
          layout="inline"
      >
        <!--    新增抽奖环节-->
        <div style="width: 100%; border: 10px solid #f0f2f5;">
          <div class="iottery-mid">
            <div class="iottery-mid-one">
              <div class="iottery-mid-one-content">
                <a-form-model-item
                    label="预计开始时间:"
                    required prop="start_time">
                  <a-date-picker
                      v-model="topForm.start_time"
                      :allowClear="false"
                      style="margin-left: 5px;width: 150px"
                      show-time
                      placeholder="请选择开始时间"
                      @change="dateChange"
                      @ok="onOk" />
                </a-form-model-item>
              </div>
              <div class="iottery-mid-one-content">
                <a-form-model-item
                    label="持续时长（秒）:"
                    required prop="last_time">
                  <a-input
                      style="margin-left: 10px;width: 180px"
                      v-model.number="topForm.last_time"
                      placeholder="请输入持续时长"/>
                </a-form-model-item>
              </div>
              <div class="iottery-mid-one-content">
                <a-form-model-item
                    label="每人抽奖次数:"
                    required prop="number_per_person">
                  <a-input
                      style="margin-left: 10px;width: 180px"
                      v-model.number="topForm.number_per_person"
                      placeholder="请输入每人抽奖次数"/>
                </a-form-model-item>
              </div>
            </div>
            <div class="iottery-mid-two">
              <div class="iottery-mid-two-content">
                <a-form-model-item
                    label="广告语:"
                    required prop="slogan">
                  <a-input
                      style="margin-left: 10px;width: 800px"
                      v-model="topForm.slogan"
                      placeholder="请输入广告语"/>
                </a-form-model-item>
              </div>
            </div>
            <div class="iottery-mid-two">
              <div class="iottery-mid-two-content">
                <a-form-model-item
                    label="抽奖规则:"
                    required prop="rule">
                  <a-textarea
                      style="margin-left: 10px;min-width: 800px"
                      v-model="topForm.rule"
                      placeholder="请输入抽奖规则"
                      :auto-size="{ minRows: 3, maxRows: 5 }"
                  />
                </a-form-model-item>
              </div>
            </div>
            <div style="width: 100%;margin-top: 60px">
              <a-table
                  style="width: 95%;margin: auto;border-top: 1px solid #e5e3e3"
                  :pagination="false"
                  :rowKey="record=>record.id"
                  :columns="columns"
                  :data-source="dataOne">
                <!--图片-->
                <span slot="pic" slot-scope="text, record">
              <div style="display: flex;width:50px;">
              <div
                  class="uploadImg" >
                <input
                    style="width: 50px;height: 50px;opacity: 0;"
                    accept="image/*"
                    type="file"
                    @change="upload('upload_img' + record.id,record.id,record.id + 'img')"
                    :id="'upload_img' + record.id" />
              </div>
              <div
                  style="margin-left: -50px"
                  v-if="record.pic"
                  class="upload_image">
                <img
                    alt=""
                    style="width: 100%;height:100%"
                    :src="record.pic"
                />
              </div>
            </div>
            </span>
                <!--奖品名称-->
                <span slot="titleVal" slot-scope="text, record">
              <a-input
                  style="width: 80%"
                  v-model="record.title"
                  placeholder="请输入奖品名称"
              />
            </span>
                <!--中奖概率-->
                <span slot="probability" slot-scope="text, record">
              <a-select
                  show-search
                  placeholder="请选择概率"
                  v-model.number="record.probability"
                  option-filter-prop="children"
                  style="width: 200px"
                  @change="probabilityChange"
              >
                <a-select-option v-for="(item,index) in numForm" :value="item">
                  {{item*100 + '%'}}
                </a-select-option>
              </a-select>
            </span>
                <!--奖品数量-->
                <span slot="count" slot-scope="text, record">
              <a-input
                  style="width: 80%"
                  v-model.number="record.count"
                  placeholder="请输入奖品数量"
              />
            </span>
              </a-table>
              <div style="width: 100%">
                <div style="width: 95%;text-align: right;margin-top: 15px">
                  <a-button @click="saveBtn">保存</a-button>
                  <a-button
                      @click="addBtn"
                      type="primary"
                      style="margin-left: 10px">新增</a-button>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <!--抽奖环节-->
        <div class="iottery-bottom">
          <div style="width: 100%;margin-top: 20px">
            <a-table
                style="width: 95%;margin: auto;border-top: 1px solid #e5e3e3"
                :pagination="false"
                :rowKey="record=>record.id"
                :columns="columnsTwo"
                :data-source="dataTwo">
            <span slot="prizeOne" slot-scope="text, record">
              <a-progress
                  style="font-size: 10px"
                  :width="40"
                  type="dashboard"
                  :percent="text*1" >
                <template #format="percent">
                  <span style="color: black">{{ percent }}</span>
                </template>
              </a-progress>
            </span>
              <span slot="date" slot-scope="text, record">
              <span>{{(record.created_time).slice(0,10)}}</span>
            </span>
              <span slot="start_time" slot-scope="text, record">
              <span>{{(record.start_time).slice(11,19)}}-{{(record.end_time).slice(11,19)}}</span>
            </span>
              <!--奖品剩余-->
              <span slot="prizeOne" slot-scope="text, record">
              <a-progress
                  style="font-size: 10px"
                  :width="40"
                  type="dashboard"
                  :percent="0" >
                  :percent="((record.content[0].count - record.content[0].prize_count)/record.content[0].count)*100" >
                <template #format="percent">
                  <span style="color: black">
                    {{record.content[0].count?Math.ceil(((record.content[0].count - record.content[0].prize_count)/record.content[0].count)*100):0}}
                  </span>
                </template>
              </a-progress>
            </span>
              <span slot="prizeTwo" slot-scope="text, record">
              <a-progress
                  style="font-size: 10px"
                  :width="40"
                  type="dashboard"
                  :percent="((record.content[1].count - record.content[1].prize_count)/record.content[1].count)*100" >
                <template #format="percent">
                  <span style="color: black">
                    {{record.content[0].count?Math.ceil(((record.content[1].count - record.content[1].prize_count)/record.content[1].count)*100):0}}</span>
                </template>
              </a-progress>
            </span>
              <span slot="prizeThree" slot-scope="text, record">
             <a-progress
                 style="font-size: 10px"
                 :width="40"
                 type="dashboard"
                 :percent="((record.content[2].count - record.content[2].prize_count)/record.content[2].count)*100" >
                <template #format="percent">
                  <span style="color: black">
                    {{record.content[0].count?Math.ceil(((record.content[2].count - record.content[2].prize_count)/record.content[2].count)*100):0}}</span>
                </template>
              </a-progress>
            </span>
              <span slot="prizeFour" slot-scope="text, record">
              <a-progress
                  style="font-size: 10px"
                  :width="40"
                  type="dashboard"
                  :percent="((record.content[3].count - record.content[3].prize_count)/record.content[3].count)*100" >
                <template #format="percent">
                  <span style="color: black">
                    {{record.content[0].count?Math.ceil(((record.content[3].count - record.content[3].prize_count)/record.content[3].count)*100):0}}</span>
                </template>
              </a-progress>
            </span>
              <span slot="system" slot-scope="text, record">
              <span v-if="new Date(record.start_time).valueOf()>new Date().valueOf() && record.is_countdown===0">
                <a-popconfirm
                     title="开启倒计时后无法关闭、编辑和删除,请确认是否开启?"
                     ok-text="是"
                     cancel-text="否"
                     @confirm="setCountdown(record.id,record.is_countdown === 0?1:0)"
                     @cancel="cancelCountdown"
                 >
                      <a>开启倒计时</a>
                  </a-popconfirm>
              <a @click="restBtn(record)" style="margin-left: 5px">修改</a>
              <a-popconfirm
                  title="是否确认删除?"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="confirm(record.id)"
                  @cancel="cancel"
              >
                  <a style="margin-left: 5px">删除</a>
              </a-popconfirm>
              </span>
              <span v-else style="color: red">
                    倒计时已开启或时间已过,无法操作
              </span>
            </span>
            </a-table>
          </div>
        </div>
      </a-form-model>
      <div style="height: 50px"></div>
      <div style="width:100%;height:50px;position: fixed;bottom: 50px;background-color: #f0f2f5;border-bottom: 1px solid #fff">
        <div style="width: 80%;display: flex;justify-content: right;margin-top: 10px">
          <a-button @click="returnBtn">返回上一层</a-button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import moment from 'moment';
  import {update} from "../../utils/update";
  import {
    delRafflesList,
    getLiveMeetRaffles,
    getRafflesContent,
    postLiveMeetRaffles,
    putRafflesContent, putTimeStart
  } from "../../service/liveMeet_api2";
  const columns = [
    {
      title: '奖品',
      dataIndex: 'prize',
      key: 'prize',
      width:'10%',
    },
    {
      title: '奖品图片',
      dataIndex: 'pic',
      key: 'pic',
      width:'10%',
      scopedSlots: { customRender: 'pic' },
    },
    {
      title: '奖品名称',
      dataIndex: 'title',
      key: 'title',
      ellipsis: true,
      scopedSlots: { customRender: 'titleVal' },
    },
    {
      title: '中奖概率',
      dataIndex: 'probability',
      key: 'probability',
      scopedSlots: { customRender: 'probability' },
    },
    {
      title: '奖品数量',
      dataIndex: 'count',
      key: 'count',
      scopedSlots: { customRender: 'count' },
    },
  ];
  const dataOne = [
    {
      id: '1',
      prize : '奖品1',
      pic : '',
      title : '',
      probability : undefined,
      count : '',
    },
    {
      id: '2',
      prize : '奖品2',
      pic : '',
      title : '',
      probability : undefined,
      count : '',
    },
    {
      id: '3',
      prize : '奖品3',
      pic : '',
      title : '',
      probability : undefined,
      count : '',
    },
    {
      id: '4',
      prize : '奖品4',
      pic : '',
      title : '',
      probability : undefined,
      count : '',
    },
  ];
  const columnsTwo = [
    {
      title: '日期',
      // dataIndex: 'date',
      key: 'date',
      width:'10%',
      scopedSlots: { customRender: 'date' },
    },
    {
      title: '抽奖时间',
      dataIndex: 'start_time',
      key: 'time',
      width:'15%',
      scopedSlots: { customRender: 'start_time' },
    },
    {
      title: '持续时长',
      dataIndex: 'last_time',
      key: 'start_time',
      ellipsis: true,
      scopedSlots: { customRender: 'last_time' },
    },
    {
      title: '奖品1剩余',
      // dataIndex: 'content',
      key: 'prizeOne',
      scopedSlots: { customRender: 'prizeOne' },
    },
    {
      title: '奖品2剩余',
      // dataIndex: 'prizeTwo',
      key: 'prizeTwo',
      scopedSlots: { customRender: 'prizeTwo' },
    },
    {
      title: '奖品3剩余',
      // dataIndex: 'prizeThree',
      key: 'prizeThree',
      scopedSlots: { customRender: 'prizeThree' },
    },
    {
      title: '奖品4剩余',
      // dataIndex: 'prizeFour',
      key: 'prizeFour',
      scopedSlots: { customRender: 'prizeFour' },
    },
    {
      title: '操作',
      // dataIndex: 'system',
      key: 'system',
      width: '15%',
      scopedSlots: { customRender: 'system' },
    },
  ];
  const dataTwo = [
  
  ];
  
  export default {
    data(){
      return{
        result:false,
        numForm:[0,0.1,0.2,0.3,0.4,0.5,0.6,0.7,0.8,0.9,1],
        dataOne,
        columns,
        dataTwo,
        columnsTwo,
        topForm:{
          activity_id:null,
          start_time:'',
          last_time:'',
          number_per_person:'',
          slogan:'',
          rule:'',
          content:[
            {
              id: '1',
              prize : '奖品1',
              pic : '',
              title : '',
              probability : undefined,
              count : '',
            },
            {
              id: '2',
              prize : '奖品2',
              pic : '',
              title : '',
              probability : undefined,
              count : '',
            },
            {
              id: '3',
              prize : '奖品3',
              pic : '',
              title : '',
              probability : undefined,
              count : '',
            },
            {
              id: '4',
              prize : '奖品4',
              pic : '',
              title : '',
              probability : undefined,
              count : '',}
          ]
        },
        rules: {
          start_time: [{required: true, message: 'Please input', trigger: 'blur'},],
          last_time: [{required: true, message: 'Please input ', trigger: 'blur'},],
          number_per_person: [{required: true, message: 'Please input ', trigger: 'blur'},],
          slogan: [{required: true, message: 'Please input', trigger: 'blur'},],
          rule: [{required: true, message: 'Please input ', trigger: 'blur'},],
        },
        listId:'',
      }
    },
    created() {
      this.liveMeetRaffles()
    },
    computed:{
      liveTitle(){
        return this.$store.state.liveTitle
      },
    },
    methods:{
      //抽奖环节详情列表
      async liveMeetRaffles() {
        let data = {
          "activity_id": this.$route.query.id,
        }
        const response = await getLiveMeetRaffles(data)
        if(response.code === 0){
          this.dataTwo = response.data.rows
        }else {
          this.$message.warning(response.message)
        }
      },
      returnBtn(){
        window.history.back()
      },
  
  
      dateChange(value,dateString){
        this.topForm.start_time = dateString
      },
      onOk(value){
      },
  
      async upload(inputId,id,idImg){
        let inputDOM = document.getElementById(inputId).files[0]
        if(inputDOM.size < 2086666.24 ){
          const image = await update(inputDOM)
          if(image.code===0){
              for (let i = 0;i<this.dataOne.length;i++){
                if(this.dataOne[i].id === id){
                  this.dataOne[i].pic=image.data.url
                }
              }
            this.$message.success('上传成功')
          //  隐藏上传按钮
            document.getElementById(idImg).style.opacity = 0
          } else{
            this.$message.warning('上传失败'+image.message)
          }
        } else{
          this.$message.warning('仅上传2M内图片')
        }
        },
       saveBtn() {
        this.$refs.ruleForm.validate(async valid => {
          if (valid) {
            await this.test()
            if (this.result === true) {
              if(this.listId !== ''){
                this.topForm.content = this.dataOne
                delete this.topForm.activity_id
                for (let i = 0; i < 4; i++) {
                  delete this.topForm.content[i].prize
                  delete this.topForm.content[i].id
                  delete this.topForm.content[i].winning_num
                }
                const response = await putRafflesContent(this.listId, this.topForm)
                if (response.code === 0) {
                  this.$message.success("保存并修改成功！")
                  //清空
                  this.topForm = {
                    activity_id: null,
                    start_time: '',
                    last_time: '',
                    number_per_person: '',
                    slogan: '',
                    rule: '',
                    content: []
                  },
                  this.dataOne = [
                        {
                          id: '1',
                          prize: '奖品1',
                          pic: '',
                          title: '',
                          probability: undefined,
                          count: '',
                        },
                        {
                          id: '2',
                          prize: '奖品2',
                          pic: '',
                          title: '',
                          probability: undefined,
                          count: '',
                        },
                        {
                          id: '3',
                          prize: '奖品3',
                          pic: '',
                          title: '',
                          probability: undefined,
                          count: '',
                        },
                        {
                          id: '4',
                          prize: '奖品4',
                          pic: '',
                          title: '',
                          probability: undefined,
                          count: '',
                        },
                      ];
                  this.listId = ''
                  //刷新列表
                  await this.liveMeetRaffles()
                } else {
                  this.$message.warning(response.message)
                }
              }else {
                this.$message.warning("还没有要编辑的抽奖，新项目请选择新增，或点击下方编辑")
              }
              this.result = false
              }else {
              this.$message.warning("有空或奖品数量小于等于0")
            }
  
          } else {
            this.$message.warning("有空，请先输入~~");
            return false;
          }
        });
  
      },
      test(){
        let v = this.dataOne
        for(let i in v){
          if(v[i].title !== '' && v[i].pic !== '' && v[i].count !== '' && v[i].count > 0 && v[i].probability !== undefined){
            this.result = true
          }else {
            this.result = false
            break;
          }
        }
      },
      addBtn() {
        this.$refs.ruleForm.validate(async valid => {
          if (valid) {
            await this.test()
            if (this.result === true) {
              this.topForm.activity_id = parseInt(this.$route.query.id)
              let dataOne = this.dataOne
              //循环去除content中的id,prize
              for (let i = 0; i < 4; i++) {
                if (dataOne[i].id !== undefined) {
                  //删除id
                  delete dataOne[i].id
                }
                if (dataOne[i].prize !== undefined) {
                  //删除id
                  delete dataOne[i].prize
                }
              }
              //赋值
              this.topForm.content = this.dataOne
              const response = await postLiveMeetRaffles(this.topForm)
              if (response.code === 0) {
                this.$message.success("添加成功！")
                this.topForm = {
                  activity_id: null,
                  start_time: '',
                  last_time: '',
                  number_per_person: '',
                  slogan: '',
                  rule: '',
                  content: []
                },
                    this.dataOne = [
                      {
                        id: '1',
                        prize: '奖品1',
                        pic: '',
                        title: '',
                        probability: undefined,
                        count: '',
                      },
                      {
                        id: '2',
                        prize: '奖品2',
                        pic: '',
                        title: '',
                        probability: undefined,
                        count: '',
                      },
                      {
                        id: '3',
                        prize: '奖品3',
                        pic: '',
                        title: '',
                        probability: undefined,
                        count: '',
                      },
                      {
                        id: '4',
                        prize: '奖品4',
                        pic: '',
                        title: '',
                        probability: undefined,
                        count: '',
                      },
                    ];
                await this.liveMeetRaffles()
              } else {
                this.$message.warning(response.message)
              }
            //  将result置为false
              this.result = false
            } else {
              this.$message.warning("有空或奖品数量小于等于0")
            }
          } else {
            this.$message.warning("有空，请输入~~")
            return false;
          }
        });
  
  
      },
      probabilityChange(value) {
        // console.log(`selected ${value}`);
      },
      async confirm(id) {
        const response = await delRafflesList(id)
        if (response.code === 0) {
          this.$message.success("删除成功！")
          //刷新列表
          await this.liveMeetRaffles()
        } else {
          this.$message.warning(response.message)
        }
      },
      async setCountdown(id,is_countdown) {
        let data = {
          is_countdown:is_countdown
        }
        const response = await putTimeStart(id,data)
        if (response.code === 0) {
          this.$message.success("成功~~！")
          //刷新列表
          await this.liveMeetRaffles()
        } else {
          this.$message.warning(response.message)
        }
      },
      cancel(){
      },
      cancelCountdown(){
      },
      //修改
      async restBtn(row) {
        this.listId = row.id
        const response = await getRafflesContent(row.id)
        if (response.code === 0) {
          let form = this.topForm
          let dataVal = response.data
          this.dataOne = dataVal.content
          this.dataOne[0].prize = '奖品1'
          this.dataOne[1].prize = '奖品2'
          this.dataOne[2].prize = '奖品3'
          this.dataOne[3].prize = '奖品4'
          form.start_time = dataVal.start_time
          form.last_time = dataVal.last_time
          form.number_per_person = dataVal.number_per_person
          form.slogan = dataVal.slogan
          form.rule = dataVal.rule
  
          //刷新列表
          // await this.liveMeetRaffles()
        } else {
          this.$message.warning(response.message)
        }
      },
  }
  }
  </script>
  
  <style lang="scss" scoped>
  .iottery{
    .iottery-title{
      height: 50px;
      line-height: 40px;
      font-weight: bold;
      color: black;
      padding-left: 15px;
    }
    .iottery-mid{
      width: 97%;
      margin: auto;
      min-height: 500px;
  
      .iottery-mid-one{
        width: 100%;
        display: flex;
        justify-content: space-between;
        .iottery-mid-one-content{
          display: flex;
          width: 33%;
          margin-top: 20px;
          padding-left: 20px;
          height: 35px;
          line-height: 35px;
        }
      }
      .iottery-mid-two{
        width: 100%;
        height: auto;
        .iottery-mid-two-content{
          width: 100%;
          display: flex;
          margin-left: 20px;
          margin-top: 20px;
          height: auto;
          line-height: 35px;
        }
      }
    }
    .iottery-bottom{
      min-height: 500px;
      border: 10px solid #f0f2f5;
    }
  }
  .upImage{
  
  }
  .uploadImg{
    border: #DDDDDD 2px dashed;
    width: 50px;
    height: 50px;
    background-image: url("../../assets/add.png");
    background-size:100% 100%;
    background-repeat:no-repeat;
    cursor: pointer;
  }
  .upload_image{
    border: #DDDDDD 2px dashed;
    width: 50px;
    height: 50px;
    //margin-left: 20px;
    text-align: center;
  }
  </style>